import { render, staticRenderFns } from "./SopTransaction.vue?vue&type=template&id=5293b806&"
import script from "./SopTransaction.vue?vue&type=script&lang=js&"
export * from "./SopTransaction.vue?vue&type=script&lang=js&"
import style0 from "./SopTransaction.vue?vue&type=style&index=0&id=5293b806&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports